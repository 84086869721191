import React from 'react';
import LinkIcon from '@mui/icons-material/Link';
import { useRecordContext } from 'react-admin';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import {
  Box, Tooltip, Typography, Stack,
} from '@mui/material';
import AssignmentRoundedIcon from '@mui/icons-material/AssignmentRounded';

export function Title() {
  const record = useRecordContext() || {};
  const { url, title, description } = record;
  return (

    <Stack alignItems="center" gap={1} direction="row">
      {description && (
        <Tooltip
          title={(
            <Box sx={{ direction: 'rtl' }}>
              { (description || '').split('|').map((line, index) => (
                <Typography fontSize={12} key={line} variant="body2" color="textSecondary">{line}</Typography>
              ))}
            </Box>
          )}
        >
          <AssignmentRoundedIcon />
        </Tooltip>
      )}
      <a href={url} target="_blank" rel="noopener noreferrer">
        {title || url.match(/youtube.com\/(?:channel\/)?@?([^/]*)/)?.[1] || (
          <WarningAmberIcon />
        )}
      </a>
    </Stack>
  );
}

Title.defaultProps = {
  label: 'Title',
};
