import React from 'react';
import { HOST, API_VERSION } from 'config';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import { useRecordContext } from 'react-admin';
import { authTokenKey } from 'authProvider';

export default function LoginAsButton() {
  const record = useRecordContext() || {};

  function handleClick() {
    const token = localStorage.getItem(authTokenKey);
    fetch(`${HOST}/api/v${API_VERSION}/client/auth/login/as`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        userId: record.id,
      }),
    }).then((res) => res.json()).then(({ data: { token } }) => {
      window.open(`https://panel.youber.app/auth/login-as?auth_token=${token}`);
    });
  }
  return (
    <a
      onClick={handleClick}
    >
      <VpnKeyIcon />
    </a>
  );
}
