import React from 'react';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { objectWithFallback } from 'utils/object-utils';
import GeneralChipField from 'shared-fields/GeneralChipField';
import { VideoState } from 'constants/video-state';
import LoopIcon from '@mui/icons-material/Loop';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';

const ICON_MAP = objectWithFallback(
  {
    [VideoState.PUBLISHED]: <CheckCircleOutlineIcon />,
    [VideoState.PUBLISHING]: <LoopIcon />,
    [VideoState.EXPIRED]: <EventBusyIcon />,
    [VideoState.WAITING_FOR_PUBLISH]: <HistoryToggleOffIcon />,
  },
  null,
);
const COLOR_MAP = objectWithFallback(
  {
    [VideoState.PUBLISHED]: 'success',
    // [VideoState.PUBLISHING]: 'info',
    // [VideoState.EXPIRED]: 'error',
    [VideoState.WAITING_FOR_PUBLISH]: 'warning',
  },
  'default',
);
const VARIANT_MAP = objectWithFallback(
  {
    [VideoState.PUBLISHED]: 'default',
  },
  'outlined',
);

export function StateField(props) {
  return (
    <GeneralChipField
      iconMap={ICON_MAP}
      colorMap={COLOR_MAP}
      variantMap={VARIANT_MAP}
      {...props}
      source="state"
    />
  );
}

StateField.defaultProps = {
  label: 'State',
};
