import Role from 'constants/role';
import { useHasRole } from 'hooks/useHasRole';
import React from 'react';
import { ReferenceField, TextField } from 'react-admin';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

function ChannelIdentifier({
  idField,
  titleField,
}) {
  return (
    <div style={{ display: 'inline-flex', gap: 5 }}>
      {idField && <TextField source="id" />}
      {titleField && <TextField source="title" />}
    </div>
  );
}
function ChannelReferenceField({
  titleField,
  serviceField,
  translationTypeField,
  idField,
  ...props
}) {
  const hasRole = useHasRole();
  if (!hasRole(Role.ROLE_ADMIN_CHANNEL_VIEW)) {
    return <VisibilityOffIcon />;
  }
  return (
    <ReferenceField reference="channel" {...props}>
      <ChannelIdentifier
        titleField={titleField}
        serviceField={serviceField}
        translationTypeField={translationTypeField}
        idField={idField}
      />
    </ReferenceField>
  );
}
ChannelReferenceField.defaultProps = {
  source: 'channelId',
  addLabel: true,
  label: 'Channel',
  idField: true,
  titleField: true,
  typeField: false,
};

export default ChannelReferenceField;
