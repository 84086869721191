export function negate(filter) {
  return `!${filter}`;
}

export function generateSelectOptions(object, hasNegate) {
  return [
    ...Object.entries(object).map((state) => ({ id: state[0], name: state[1] })),
    ...(hasNegate
      ? Object.entries(object).map((state) => ({ id: negate(state[0]), name: `IS NOT ${state[1]}` }))
      : []),
  ];
}
