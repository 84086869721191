import React from 'react';
import Role from 'constants/role';
import { useHasRole } from 'hooks/useHasRole';
import {
  Datagrid,
  DateField,
  ExportButton,
  Filter,
  List,
  Resource,
  SearchInput,
  ShowGuesser,
  TextField,
  TopToolbar,
} from 'react-admin';
import UserReferenceField from 'shared-fields/UserReferenceField';
import UserReferenceInput from 'shared-fields/UserReferenceInput';
import TextSnippetRoundedIcon from '@mui/icons-material/TextSnippetRounded';
import { DetailsField } from './DetailsField';
import { RecordReferenceField } from './RecordReferenceField';
import { ActionField } from './ActionField';

function ActivityLoggerFilter(props) {
  return (
    <Filter {...props}>
      <SearchInput source="searchQuery" alwaysOn />
      <UserReferenceInput alwaysOn />
    </Filter>
  );
}

export function ActivityLoggerListContent() {
  return (
    <Datagrid bulkActionButtons={false}>
      <TextField source="id" />
      <ActionField />
      <UserReferenceField label="Admin" />
      <RecordReferenceField />
      <DetailsField />
      <DateField source="createdAt" label="Created" showTime locales="fa-IR" />
      <DateField source="updatedAt" label="Updated" showTime locales="fa-IR" />
    </Datagrid>
  );
}
export function ActivityLoggerList() {
  const hasRole = useHasRole();
  return (
    <List
      sort={{ field: 'id', order: 'DESC' }}
      perPage={25}
      filters={<ActivityLoggerFilter />}
      actions={(
        <TopToolbar>
          {hasRole(Role.ROLE_SUPER_ADMIN) && <ExportButton />}
        </TopToolbar>
      )}
    >
      <ActivityLoggerListContent />
    </List>
  );
}

export const renderActivityLoggerResource = (roles) => (
  <Resource
    options={{
      label: 'Activities',
    }}
    name="activity-logger"
    list={ActivityLoggerList}
    show={ShowGuesser}
    icon={TextSnippetRoundedIcon}
  />
);
