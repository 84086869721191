import { Stack, Typography } from '@mui/material';
import React from 'react';
import { useRecordContext } from 'react-admin';
import ChannelReferenceField from 'shared-fields/ChannelReferenceField';
import IncomeReferenceField from 'shared-fields/IncomeReferenceField';
import TransactionReferenceField from 'shared-fields/TransactionReferenceField';
import UserReferenceField from 'shared-fields/UserReferenceField';

const ReferenceMap = {
  User: UserReferenceField,
  Channel: ChannelReferenceField,
  Income: IncomeReferenceField,
  WalletTransaction: TransactionReferenceField,
};
export function RecordReferenceField() {
  const { recordModel } = useRecordContext() || {};
  if (!recordModel) return '-';
  const Component = ReferenceMap[recordModel];
  if (!Component) {
    return '-';
  }

  return (
    <Stack direction="row" alignItems="center" gap={1}>
      <Typography fontSize={12}>
        { recordModel}
        :
      </Typography>
      <Component source="recordId" />
    </Stack>
  );
}

RecordReferenceField.defaultProps = {
  label: 'Record',
};
