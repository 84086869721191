import React from 'react';
import { ReferenceField, TextField } from 'react-admin';

function TransactionReferenceField(props) {
  return (
    <ReferenceField reference="wallet-transaction" {...props}>
      #
      <TextField source="id" />
    </ReferenceField>
  );
}
TransactionReferenceField.defaultProps = {
  label: 'Transaction',
  source: 'transactionId',
};

export default TransactionReferenceField;
