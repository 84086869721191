import Role from 'constants/role';
import { useHasRole } from 'hooks/useHasRole';
import React from 'react';
import { ReferenceInput, AutocompleteInput, required } from 'react-admin';

const optionText = (r) => (r?.id
  ? [
    ['ID', r.id],
    ['NAME', r.name],
    r.channels?.length
      ? ['CHANNEL', r.channels[0].title || r.channels[0].id]
      : null,
  ].filter(Boolean).map(([key, value]) => `${key}: ${value}`).join(' | ')
  : '');
function UserReferenceInput({ validate, disabled, ...props }) {
  const hasRole = useHasRole();
  if (!hasRole(Role.ROLE_ADMIN_USER_VIEW)) {
    return null;
  }
  return (
    <ReferenceInput reference="user" {...props}>
      <AutocompleteInput
        defaultValue={null}
        optionText={optionText}
        filterToQuery={(searchQuery) => ({
          // isTemporary: false,
          searchQuery,
          _attributes: 'id,name',
        })}
        matchSuggestion={() => true}
        validate={validate}
        sx={{ width: '100%' }}
        disabled={disabled}
      />
    </ReferenceInput>
  );
}

UserReferenceInput.defaultProps = {
  source: 'userId',
  label: 'User',
  validate: [],
};

export default UserReferenceInput;
