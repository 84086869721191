import { ArrowRight } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import React from 'react';
import { useRecordContext } from 'react-admin';

export function DetailsField() {
  const { details } = useRecordContext() || {};
  if (!details) return '-';

  return <div>{printDetails(details)}</div>;
}

const printDetails = (details, prefix = '', level = 0) => {
  if (typeof details !== 'object' || details === null) {
    return null;
  }

  function printValue(value) {
    if (value === null) {
      return '-';
    }
    if (typeof value === 'object') {
      return JSON.stringify(value);
    }
    return value;
  }
  return Object.entries(details).map(([key, value]) => {
    if (value && (value.hasOwnProperty('old') || value.hasOwnProperty('new'))) {
      return (
        <Stack
          key={`${prefix}${key}`}
          direction="row"
          sx={{ ml: level }}
          gap={0.3}
          alignItems="center"
        >
          <Typography fontSize={12} fontWeight="bold">
            {prefix}
            {' '}
          </Typography>
          <Typography fontSize={12} fontWeight="bold">
            {key}
            :
            {' '}
          </Typography>
          <Typography fontSize={12} color="error">
            {printValue(value.old)}
          </Typography>
          <ArrowRight style={{ fontSize: 12 }} />
          <Typography fontSize={12} color="success.main">
            {printValue(value.new)}
          </Typography>
        </Stack>
      );
    }
    return (
      <React.Fragment key={`${prefix}${key}`}>
        <Typography fontSize={12} fontWeight="bold">
          {prefix}
          {key}
        </Typography>
        {printDetails(value, ` ${prefix}  `, level + 1)}
      </React.Fragment>
    );
  });
};
DetailsField.defaultProps = {
  label: 'Details',
};
