import React from 'react';
import { HOST, API_VERSION } from 'config';
import { useRecordContext } from 'react-admin';
import { authTokenKey } from 'authProvider';
import DownloadIcon from '@mui/icons-material/Download';
import { Button, Chip } from '@mui/material';

export default function DownloadDocButton({ title, type }) {
  const record = useRecordContext() || {};

  function handleClick() {
    const token = localStorage.getItem(authTokenKey);
    fetch(`${HOST}/api/v${API_VERSION}/user/${record.id}/doc/${type}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }).then((res) => res.json()).then(({ url }) => {
      // console.log(data);
      window.open(url, '_blank', 'noopener');
    }).catch((e) => {
      console.log(e);
    });
  }
  if (!record.docs?.[type]) {
    return null;
  }
  return (
    <Button size="small" variant="contained" startIcon={<DownloadIcon />} onClick={handleClick} sx={{ width: 200 }}>{title}</Button>
  );
}
