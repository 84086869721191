import Role from 'constants/role';
import { useHasRole } from 'hooks/useHasRole';
import React from 'react';
import { ReferenceField, TextField } from 'react-admin';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

function IncomeReferenceField(props) {
  const hasRole = useHasRole();
  if (!hasRole(Role.ROLE_ADMIN_INCOME_VIEW)) {
    return <VisibilityOffIcon />;
  }
  return (
    <ReferenceField reference="income" {...props}>
      <div style={{ display: 'inline-flex', gap: 5 }}>
        <TextField source="id" />
        |
        <TextField source="amount" />
      </div>
    </ReferenceField>
  );
}
IncomeReferenceField.defaultProps = {
  label: 'Income',
  source: 'incomeId',
};

export default IncomeReferenceField;
