import {
  AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK, AUTH_GET_PERMISSIONS,
} from 'react-admin';
// import { executeRecaptchAction } from 'utils/recaptcha-utils';
import { Role } from './constants/role';
import { HOST, API_VERSION } from './config';

export const authTokenKey = 'yb-auth-token';
// export default async (type, params) => {
//   console.log({ type, params });
//   if (type === AUTH_LOGIN) {
//     const { username, password } = params;
//     // const recaptchaToken = await executeRecaptchAction('auth_login');
//     const request = new Request(
//       `${HOST}/api/v${API_VERSION}/client/auth/login/email`,
//       {
//         method: 'POST',
//         body: JSON.stringify({ email: username, password, recaptchaToken: '..' }),
//         headers: new Headers({ 'Content-Type': 'application/json' }),
//       },
//     );
//     return fetch(request)
//       .then((response) => {
//         if (response.status < 200 || response.status >= 300) {
//           throw new Error(response.statusText);
//         }
//         return response.json();
//       })
//       .then(({ data: { token } }) => {
//         if (isAdmin(token)) {
//           localStorage.setItem(authTokenKey, token);
//         }
//       });
//   }
//   // return Promise.resolve();
//   // called when the user attempts to  log in
//   // if (type === AUTH_LOGIN) {
//   //     const { username } = params;
//   //     localStorage.setItem('username', username);
//   //     // accept all username/password combinations
//   //     return Promise.resolve();
//   // }
//   // called when the user clicks on the logout button
//   if (type === AUTH_LOGOUT) {
//     localStorage.removeItem(authTokenKey);
//     return Promise.resolve();
//   }
//   // called hen the API returns an error
//   if (type === AUTH_ERROR) {
//     const { status } = params;
//     if (status === 401) {
//       localStorage.removeItem(authTokenKey);
//       return Promise.reject();
//     }
//     return Promise.resolve();
//   }
//   // called when the user navigates to a new location
//   if (type === AUTH_CHECK) {
//     const token = localStorage.getItem(authTokenKey);
//     return token && isAdmin(token) ? Promise.resolve() : Promise.reject();
//   }
//   if (type === AUTH_GET_PERMISSIONS) {
//     const token = localStorage.getItem(authTokenKey);
//     if (!token) {
//       return Promise.reject();
//     }
//     const user = JSON.parse(decodeURIComponent(atob(token.split('.')[1])));
//     return Promise.resolve(user.roles);
//   }
//   return Promise.reject(new Error('Unknown method'));
// };

export default {
  login: ({ username, password }) => {
    const request = new Request(`${HOST}/api/v${API_VERSION}/client/auth/login/email`, {
      method: 'POST',
      body: JSON.stringify({ email: username, password }),
      headers: new Headers({ 'Content-Type': 'application/json' }),
    });
    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(({ data: { token } }) => {
        console.log('set token', token);
        localStorage.setItem(authTokenKey, token);
      });
  },
  checkError: (error) => {
    if (!error) {
      return Promise.reject(new Error('Unknown Authorized'));
    } if (error?.status === 401) {
      localStorage.removeItem(authTokenKey);
      return Promise.reject(new Error('Not Authorized'));
    }
    return Promise.resolve();
  },
  checkAuth: () => {
    const token = localStorage.getItem(authTokenKey);
    return token && isAdmin(token) ? Promise.resolve() : Promise.reject();
  },
  logout: () => {
    localStorage.removeItem(authTokenKey);
    return Promise.resolve();
  },
  getIdentity: () => { /* ... */ },
  getPermissions: () => {
    const token = localStorage.getItem(authTokenKey);
    if (!token) {
      return Promise.reject();
    }
    const user = JSON.parse(decodeURIComponent(atob(token.split('.')[1])));
    return Promise.resolve(user.roles);
  },
};

function isAdmin(token) {
  try {
    const user = JSON.parse(decodeURIComponent(atob(token.split('.')[1])));
    return user.roles && user.roles.indexOf(Role.ROLE_ADMIN_LOGIN.toString()) !== -1;
  } catch (e) {
    return false;
  }
}
