import React from 'react';
import {
  List,
  Edit,
  Datagrid,
  DateField,
  TextField,
  EditButton,
  TextInput,
  ShowButton,
  TabbedForm,
  FormTab,
  Filter,
  SearchInput,
  Resource,
  TopToolbar,
  ExportButton,
  ShowGuesser,
} from 'react-admin';
import UserReferenceField from 'shared-fields/UserReferenceField';
import UserReferenceInput from 'shared-fields/UserReferenceInput';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { rolesMatch } from 'utils/user';
import Role from 'constants/role';
import { useHasRole } from 'hooks/useHasRole';
import ChannelReferenceField from 'shared-fields/ChannelReferenceField';
import ChannelReferenceInput from 'shared-fields/ChannelReferenceInput';
import RangeField from 'shared-fields/RangeField';
import { StateField } from './StateField';
import ProgressField from './ProgressField';
import { Url } from './Url';

function VideoFilter(props) {
  return (
    <Filter {...props}>
      <SearchInput source="searchQuery" alwaysOn />
      <UserReferenceInput alwaysOn />
      <ChannelReferenceInput alwaysOn />
    </Filter>
  );
}

export function VideoListContent() {
  const hasRole = useHasRole();
  return (
    <Datagrid bulkActionButtons={false}>
      <TextField source="id" />
      <TextField source="title" />
      <StateField />
      <TextField source="fileState" />
      <TextField source="retry" />
      <RangeField max={5 * 1024 * 1024 * 1024} source="size" postfix="B" humanize />
      <ProgressField />
      <Url />
      <TextField source="filename" />
      <UserReferenceField />
      <ChannelReferenceField />
      <DateField source="createdAt" label="Created" showTime locales="fa-IR" />
      <DateField source="updatedAt" label="Updated" showTime locales="fa-IR" />
      <DateField source="publishedAt" label="Published" showTime locales="fa-IR" />
      {
        hasRole(Role.ROLE_ADMIN_VIDEO_EDIT) && (
          <EditButton />
        )
      }
      <ShowButton />
    </Datagrid>
  );
}
export function VideoList() {
  const hasRole = useHasRole();
  return (
    <List
      sort={{ field: 'id', order: 'DESC' }}
      perPage={25}
      filters={<VideoFilter />}
      actions={(
        <TopToolbar>
          {hasRole(Role.ROLE_SUPER_ADMIN) && <ExportButton />}
          {/* <FilterButton  /> */}
        </TopToolbar>
      )}
    >
      <VideoListContent />
    </List>
  );
}

export function VideoEdit(props) {
  return (
    <Edit mutationMode="optimistic" {...props}>
      <TabbedForm>
        <FormTab label="Video">
          <TextInput source="id" disabled />
          <TextInput source="s3.key" />
          <TextInput source="s3.bucket" />
          <TextInput source="title" />
          <TextInput source="state" />
          <TextInput source="fileState" />
          <TextInput source="description" multiline />
          <TextInput source="retry" />
          <UserReferenceInput />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
}
// export function VideoCreate(props) {
//   return (
//     <Create mutationMode="optimistic" {...props}>
//       <TabbedForm>
//         <FormTab label="Video">
//           <TextInput source="video.url" />
//           <TextInput source="channel.url" defaultValue={null} />
//           <NumberInput source="fee" />
//           <NumberInput source="requestedCount" />
//           <NumberInput source="requestedDuration" />
//           <TextInput source="description" multiline />
//           <SelectInput source="state" choices={VideoStateChoices} />
//           <SelectInput source="type" choices={typeChoices} />
//           <UserReferenceInput />
//           <BooleanInput source="isTest" label="Test" />
//         </FormTab>
//         <FormTab label="Coin Transactions">
//           <TransactionsTabContent />
//         </FormTab>
//       </TabbedForm>
//     </Create>
//   );
// }

// export function VideoShow(props) {
//   return (
//     <Show {...props}>
//       <TabbedShowLayout>
//         <Tab label="general">
//           <ShowGuesser {...props} />
//         </Tab>
//         <Tab label="Transactions">
//           <TransactionsTabContent />
//         </Tab>
//       </TabbedShowLayout>
//     </Show>
//   );
// }

// const typeChoices = generateSelectOptions(VideoType);
// const VideoStateChoices = generateSelectOptions(VideoState);

export const renderVideoResource = (roles) => (
  <Resource
    options={{
      label: 'Uploads',
    }}
    name="Video"
    list={VideoList}
    show={ShowGuesser}
    edit={rolesMatch({ roles, role: Role.ROLE_ADMIN_VIDEO_EDIT }) && VideoEdit}
    icon={CloudUploadIcon}
  />
);
