import Role from 'constants/role';
import { useHasRole } from 'hooks/useHasRole';
import React from 'react';
import { ReferenceInput, AutocompleteInput } from 'react-admin';
import generateOptionText from 'utils/autocomplete-option-text';

const optionText = generateOptionText('title');

function ChannelReferenceInput({ disabled, ...props }) {
  const hasRole = useHasRole();
  if (!hasRole(Role.ROLE_ADMIN_CHANNEL_VIEW)) {
    return null;
  }
  return (
    <ReferenceInput reference="channel" allowEmpty {...props}>
      <AutocompleteInput
        defaultValue={null}
        filterToQuery={(searchText) => ({
          searchQuery: searchText,
          _attributes: 'id,title',
        })}
        optionText={optionText}
        matchSuggestion={() => true}
        disabled={disabled}
      />
    </ReferenceInput>
  );
}
ChannelReferenceInput.defaultProps = {
  source: 'channelId',
  label: 'Channel',
};

export default ChannelReferenceInput;
