import { Chip, Typography } from '@mui/material';
import { useRecordContext } from 'react-admin';
import React from 'react';

export function AdSenseField() {
  const record = useRecordContext() || {};
  const { adSenseId } = record;
  if (!adSenseId) {
    return '-';
  }
  return <Chip label={adSenseId.slice(-4)} size="small" />;
}

AdSenseField.defaultProps = {
  label: 'AdSense',
};
