import React from 'react';
import { HOST, API_VERSION } from 'config';
import { useRecordContext } from 'react-admin';
import { authTokenKey } from 'authProvider';
import DownloadIcon from '@mui/icons-material/Download';
import {
  alpha,
  Button, Chip, Menu, MenuItem, Stack, styled,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import GavelRoundedIcon from '@mui/icons-material/GavelRounded';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

// export default function DownloadContractButton1() {
//   const record = useRecordContext() || {};
//   const navigate = useNavigate();

//   function handleClick() {
//     navigate('/user-contract/', { state: { record } });
//   }

//   return (
//     <Chip icon={<DownloadIcon />} label="Contract" onClick={handleClick} />
//   );
// }

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: 'rgb(55, 65, 81)',
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

export default function DownloadContractButton() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const record = useRecordContext() || {};
  const navigate = useNavigate();

  function handleClick(channel) {
    navigate('/user-contract/', { state: { record, channelUrl: channel.url } });
  }
  function handleTetherClick() {
    navigate('/user-contract/tether', { state: { record } });
  }
  const handleButtonClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Stack direction="row" gap={2}>
        <Button
          aria-controls={open ? 'demo-customized-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          variant="contained"
          disableElevation
          onClick={handleButtonClick}
          startIcon={<GavelRoundedIcon />}
          endIcon={<KeyboardArrowDownIcon />}
          size="small"
        >
          Contract
        </Button>
        <Button
          variant="contained"
          onClick={handleTetherClick}
          startIcon={<GavelRoundedIcon />}
          size="small"
        >
          Tether Contract
        </Button>
      </Stack>

      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {
          record.channels.map((channel) => (
            <MenuItem onClick={() => handleClick(channel)} disableRipple>
              {channel.title}
            </MenuItem>
          ))
        }
      </StyledMenu>
    </div>
  );
}
