export function humanize(value) {
  if (value >= 1000000000) {
    const val = (value / 1000000000);
    if (val.toFixed(1).length > 3) return `${val.toFixed(0)}G`;
    return `${val.toFixed(1)}G`;
  }

  if (value >= 1000000) {
    const val = (value / 1000000);
    if (val.toFixed(1).length > 3) return `${val.toFixed(0)}M`;
    return `${val.toFixed(1)}M`;
  }
  if (value >= 1000) {
    const val = (value / 1000);
    if (val.toFixed(1).length > 3) return `${val.toFixed(0)}K`;
    return `${val.toFixed(1)}K`;
  }

  if (value >= 100) {
    return `${value.toFixed(0)}`;
  }

  if (value >= 10) {
    return `${value.toFixed(1)}`;
  }

  if (value >= 1) {
    return `${value.toFixed(2)}`;
  }

  if (value < 1 && value > 0) {
    return value.toPrecision(3);
  }

  return `${value}`;
}

export function getShebaBankName(iban) {
  const BANK_CODES = {
    '010': 'مرکزی',
    '011': 'صنعت و معدن',
    '012': 'ملت',
    '013': 'رفاه',
    '014': 'مسکن',
    '015': 'سپه',
    '016': 'کشاورزی',
    '017': 'ملّی',
    '018': 'تجارت',
    '019': 'صادرات',
    '020': 'توسعه صادرات',
    '021': 'پست بانک',
    '022': 'توسعه تعاون',
    '051': 'موسسه اعتباری توسعه',
    '053': 'کارآفرین',
    '054': 'پارسیان',
    '055': 'اقتصادنوین',
    '056': 'سامان',
    '057': 'پاسارگاد',
    '058': 'سرمایه',
    '059': 'سینا',
    '060': 'مهر ایرانیان',
    '061': 'شهر',
    '062': 'آینده',
    '063': 'سپه',
    '064': 'گردشگری',
    '066': 'دی',
    '070': 'رسالت',
    '078': 'خاورمیانه',
    '069': 'ایران‌زمین',
  };
  if (!isShebaValid(iban)) {
    return null;
  }
  return BANK_CODES[filterToDigits(iban)?.slice(2, 5)] || null;
}
export function toLatinDigit(str) {
  if (!str) {
    return '';
  }
  const ENGLISH_DIGITS = {
    '۱': '1', // Persian keyword
    '۲': '2',
    '۳': '3',
    '۴': '4',
    '۵': '5',
    '۶': '6',
    '۷': '7',
    '۸': '8',
    '۹': '9',
    '۰': '0',
    '١': '1', // Arabic Keyword
    '٢': '2',
    '٣': '3',
    '٤': '4',
    '٥': '5',
    '٦': '6',
    '٧': '7',
    '٨': '8',
    '٩': '9',
    '٠': '0',
  };

  return str
    .toString()
    .split('')
    .map((digit) => (ENGLISH_DIGITS[digit]) || digit)
    .join('');
}
export function toPersianDigit(str) {
  if (!str) {
    return '';
  }
  const PERSIAN_DIGITS = {
    1: '۱', // English keyword
    2: '۲',
    3: '۳',
    4: '۴',
    5: '۵',
    6: '۶',
    7: '۷',
    8: '۸',
    9: '۹',
    0: '۰',
    '١': '۱', // Arabic Keyword
    '٢': '۲',
    '٣': '۳',
    '٤': '۴',
    '٥': '۵',
    '٦': '۶',
    '٧': '۷',
    '٨': '۸',
    '٩': '۹',
    '٠': '۰',
  };

  return str
    .toString()
    .split('')
    .map((digit) => (PERSIAN_DIGITS[digit]) || digit)
    .join('');
}

export function filterToDigits(iban) {
  if (!iban) {
    return iban;
  }
  return toLatinDigit(iban).replace(/[^0-9]/g, '');
}
export function isShebaValid(iban) {
  iban = `IR${filterToDigits(iban)}`;
  const objRegExp = /[a-zA-Z]{2}[0-9]{2}[a-zA-Z0-9]{4}[0-9]{7}([a-zA-Z0-9]?){0,16}/;
  if (!objRegExp.test(iban)) {
    return false;
  }
  let ibanNr = iban.substring(4) + iban.substring(0, 4);
  for (let nr = 10; nr <= 35; nr++) {
    ibanNr = ibanNr.replace(String.fromCharCode(nr + 55), nr.toString());
  }
  let currPart = '';
  let leftOver;
  for (let currDigit = 0; currDigit < ibanNr.length; currDigit++) {
    currPart += ibanNr.charAt(currDigit);
    const currNumber = parseInt(currPart, 10);
    if (currNumber >= 97) {
      leftOver = currNumber % 97;
      currPart = leftOver.toString();
    }
  }
  return leftOver === 1 || leftOver === 0;
}
