import React from 'react';
import { useRecordContext } from 'react-admin';

export default function DescriptionField() {
  const record = useRecordContext() || {};
  const { description, systemDescription } = record;

  return (
    <span style={{ whiteSpace: 'nowrap' }}>
      {
        [systemDescription, description].filter(Boolean).join('|').split('|').flatMap((r) => (
          <>
            {r}
            <br />
          </>
        ))
      }

    </span>
  );
}

DescriptionField.defaultProps = {
  label: 'Description',
};
