import React from 'react';
import { useRecordContext } from 'react-admin';
import { getShebaBankName } from 'utils/digit';

const BankNameField = () => {
  const record = useRecordContext() || {};

  return `Bank: ${getShebaBankName(record?.bankAccount?.sheba) || '-'}`;
};
BankNameField.defaultProps = {
  label: 'Bank name',
};

export default BankNameField;
