import React from 'react';
import { useRecordContext } from 'react-admin';
import { ChannelState } from 'constants/channel-state.enum';
import GppBadIcon from '@mui/icons-material/GppBad';
import GppGoodIcon from '@mui/icons-material/GppGood';

export function Health() {
  const record = useRecordContext() || {};
  const { overallGoodStanding, state, id } = record;
  if (!overallGoodStanding) {
    return (
      <GppBadIcon sx={{
        color: state === ChannelState.MONETIZED ? 'error.main' : 'grey.400',
      }}
      />
    );
  }
  return (
    <GppGoodIcon sx={{
      color: state === ChannelState.MONETIZED ? 'success.main' : 'grey.500',
    }}
    />
  );
}

Health.defaultProps = {
  label: 'Health',
};
