import React from 'react';
import { useRecordContext } from 'react-admin';
import RangeField from 'shared-fields/RangeField';

function ProgressField() {
  const { s3: { parts } = {}, fileState } = useRecordContext() || {};
  const allParts = Object.values(parts || {});
  const progress = parts ? Math.floor(100 * allParts.filter((p) => p.ETag).length / allParts.length) : 0;
  return <RangeField value={progress} postfix="%" palette="green" />;
}

ProgressField.defaultProps = {
  label: 'progress',
  source: 'progress',
  addLabel: true,
};
export default ProgressField;
