import Role from 'constants/role';
import { useHasRole } from 'hooks/useHasRole';
import React from 'react';
import { ReferenceField, TextField } from 'react-admin';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

function UserReferenceField(props) {
  const hasRole = useHasRole();
  if (!hasRole(Role.ROLE_ADMIN_USER_VIEW)) {
    return <VisibilityOffIcon />;
  }
  return (
    <ReferenceField reference="user" {...props}>
      <div style={{ display: 'inline-flex', gap: 5 }}>
        <TextField source="id" />
        |
        <TextField source="name" />
      </div>
    </ReferenceField>
  );
}
UserReferenceField.defaultProps = {
  label: 'User',
  source: 'userId',
};

export default UserReferenceField;
