import React from 'react';
import { useRecordContext } from 'react-admin';
import { humanize as humanizeNumber } from 'utils/digit';

function RangeField({
  source, max = 100, palette = 'warm', postfix, value: valueProp,
  humanize,
}) {
  const record = useRecordContext() || {};
  const value = valueProp ?? Math.round(record[source] || 0);
  const selectedPalette = PALETTE[palette];
  const colorRange = Math.floor(Math.min(value / max, 1) * (selectedPalette.length - 1));
  return (
    <code style={{
      color: selectedPalette[colorRange],
    }}
    >
      {humanize ? humanizeNumber(value) : value }
      { postfix}
    </code>
  );
}

RangeField.defaultProps = {
};
export default RangeField;

const PALETTE = {
  green: [
    '#FFFFFF',
    '#F0FFF0',
    '#DBFFDB',
    '#B3FFB3',
    '#8CFF8C',
    '#66FF66',
    '#39FF39',
    '#0DFF0D',
    '#00E600',
    '#00CC00',
  ],
  warm: [
    '#FFFFFF',
    '#FEFFCC',
    '#FDFE99',
    '#FBFD66',
    '#FAFC33',
    '#F9FB00',
    '#F6F900',
    '#F3F700',
    '#F0F500',
    '#EDF300',
    '#EAEA00',
    '#FFEA00',
    '#FFDF00',
    '#FFD400',
    '#FFC900',
    '#FFBE00',
    '#FFB300',
    '#FFA800',
    '#FF9D00',
    '#FF9200',
    '#FF8700',
    '#FF7C00',
    '#FF7100',
    '#FF6600',
    '#FF5B00',
    '#FF5000',
    '#FF4500',
    '#FF3A00',
    '#FF2F00',
    '#FF2400',
  ],
};
