import React from 'react';
import GeneralChipField from 'shared-fields/GeneralChipField';
import { objectWithFallback } from 'utils/object-utils';

import AddBoxIcon from '@mui/icons-material/AddBox';
import DeleteIcon from '@mui/icons-material/Delete';
import EditRoundedIcon from '@mui/icons-material/EditRounded';

const ICON_MAP = objectWithFallback(
  {
    UPDATE_RECORD: <EditRoundedIcon />,
    CREATE_RECORD: <AddBoxIcon />,
    DELETE_RECORD: <DeleteIcon />,
  },
  null,
);
const COLOR_MAP = objectWithFallback(
  {
    UPDATE_RECORD: 'info',
    CREATE_RECORD: 'success',
    DELETE_RECORD: 'error',
  },
  'default',
);
const VARIANT_MAP = objectWithFallback(
  {},
  'outlined',
);

export function ActionField(props) {
  return (
    <GeneralChipField
      iconMap={ICON_MAP}
      colorMap={COLOR_MAP}
      variantMap={VARIANT_MAP}
      {...props}
      source="action"
    />
  );
}

ActionField.defaultProps = {
  label: 'Action',
};
