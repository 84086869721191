import { fetchUtils } from 'react-admin';
import { authTokenKey } from 'authProvider';
import restClient from './base-rest-client';
import { HOST, API_VERSION } from '../config';

const fetchJson = async (url, options = {}) => {
  const token = localStorage.getItem(authTokenKey);
  options.headers = new Headers({
    Authorization: `Bearer ${token}`,
    Accept: 'application/json',
  });
  return fetchUtils.fetchJson(url, options).catch((e) => {
    throw new Error(e?.body?.error || e.message);
  });
};
export default restClient(`${HOST}/api/v${API_VERSION}`, fetchJson);
