import React, { useEffect } from 'react';
import { Admin, CustomRoutes } from 'react-admin';
import { renderUserResource } from 'views/user';
import { renderVideoResource } from 'views/video';
import { renderTransactionResource } from 'views/walletTransaction';
import dataProvider from 'data-provider';
import { loadRecaptchaScript } from 'utils/recaptcha-utils';
import { renderChannelResource } from 'views/channel';
import { renderIncomeResource } from 'views/income';
import { theme } from 'theme';
import Role from 'constants/role';
import { rolesMatch } from 'utils/user';
import { Route } from 'react-router-dom';
import Contract from 'views/user/Contract/Contract';
import ContractTether from 'views/user/Contract/ContractTether';
import { renderActivityLoggerResource } from 'views/activity-logger';
import authProvider from './authProvider';

function App() {
  // useEffect(loadRecaptchaScript, []);
  // const hasRole = useHasRole();
  return (
    <Admin
      authProvider={authProvider}
      dataProvider={dataProvider}
      title="Youber"
      theme={theme}
    >
      {(roles) => (
        <>
          {rolesMatch({ roles, role: Role.ROLE_ADMIN_CHANNEL_VIEW }) && renderChannelResource(roles)}
          {rolesMatch({ roles, role: Role.ROLE_ADMIN_USER_VIEW }) && renderUserResource(roles)}
          {rolesMatch({ roles, role: Role.ROLE_ADMIN_WALLET_TRANSACTION_VIEW }) && renderTransactionResource(roles)}
          {rolesMatch({ roles, role: Role.ROLE_ADMIN_VIDEO_VIEW }) && renderVideoResource(roles)}
          {rolesMatch({ roles, role: Role.ROLE_ADMIN_INCOME_VIEW }) && renderIncomeResource(roles)}
          {rolesMatch({ roles, role: Role.ROLE_SUPER_ADMIN }) && renderActivityLoggerResource(roles)}
          <CustomRoutes>
            {rolesMatch({ roles, role: Role.ROLE_ADMIN_USER_VIEW }) && <Route path="/user-contract/tether" element={<ContractTether />} />}
            {rolesMatch({ roles, role: Role.ROLE_ADMIN_USER_VIEW }) && <Route path="/user-contract" element={<Contract />} />}
          </CustomRoutes>
        </>

      )}

    </Admin>
  );
}

export default App;
