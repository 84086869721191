import { amber, red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';
import { defaultTheme } from 'react-admin';

export const RAW_THEME = {
  ...defaultTheme,
  typography: {
    fontFamily: ['Vazirmatn', 'Roboto', 'Helvetica Neue', 'sans-serif'].join(
      ',',
    ),
    ...defaultTheme.typography,
  },
  palette: {
    mode: 'dark',
    primary: amber,
    secondary: red,
  },
  components: {
    ...defaultTheme.components,
    MuiFilledInput: {
      styleOverrides: {
        root: {
          minWidth: 200,
        },
      },
    },
  },
};
export const theme = createTheme(RAW_THEME);
