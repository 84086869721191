import React from 'react';
import { TextField, useRecordContext } from 'react-admin';

// eslint-disable-next-line react/prop-types
const TitleField = () => {
  const record = useRecordContext() || {};
  return `${record.id} | ${record.title || record.name}`;
};

TitleField.defaultProps = {
  ...TextField.defaultProps,
};
export default TitleField;
