import React from 'react';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { objectWithFallback } from 'utils/object-utils';
import GeneralChipField from 'shared-fields/GeneralChipField';
import { useRecordContext } from 'react-admin';

const ICON_MAP = objectWithFallback(
  {
    REGISTERED: <CheckCircleOutlineIcon />,
    COMPLETED: <CheckCircleOutlineIcon />,
  },
  null,
);
const COLOR_MAP = objectWithFallback(
  {
    REGISTERED: 'secondary',
    COMPLETED: 'success',
  },
  'default',
);
const VARIANT_MAP = objectWithFallback(
  {
    COMPLETED: 'default',
  },
  'outlined',
);

export function ContractStateField(props) {
  const campaign = useRecordContext() || {};

  return (
    <GeneralChipField
      iconMap={ICON_MAP}
      colorMap={COLOR_MAP}
      variantMap={VARIANT_MAP}
      {...props}
      source="contractState"
    />
  );
}

ContractStateField.defaultProps = {
  label: 'Contract',
};
