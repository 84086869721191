import React from 'react';
import LinkIcon from '@mui/icons-material/Link';
import { useRecordContext } from 'react-admin';

export function Url() {
  const record = useRecordContext() || {};
  const { url } = record;
  if (!url) {
    return '-';
  }
  return (
    <a href={url} target="_blank" rel="noopener noreferrer">
      <LinkIcon />
    </a>
  );
}

Url.defaultProps = {
  label: 'Link',
};
