import { RoleTree } from 'constants/role';

export function rolesMatch({
  roles: UserRoles,
  role,
}) {
  if (role == null || !UserRoles) {
    return false;
  }
  // eslint-disable-next-line no-restricted-syntax
  for (const r of UserRoles) {
    if (containRole(r, role)) {
      return true;
    }
  }
  return false;

  function containRole(sourceRole, targetRole) {
    if (sourceRole === targetRole) {
      return true;
    }
    if (RoleTree.hasOwnProperty(sourceRole)) {
      // eslint-disable-next-line no-restricted-syntax
      for (const r of RoleTree[sourceRole]) {
        if (containRole(r, targetRole)) {
          return true;
        }
      }
    }

    return false;
  }
}
