import React from 'react';
import { TextField, useRecordContext } from 'react-admin';

// eslint-disable-next-line react/prop-types
function AmountField({ source, prefix = null }) {
  const record = useRecordContext() || {};
  const value = record[source];
  if (value == null) {
    return null;
  }
  return (
    <code style={{ fontSize: 14, color: value < 0 ? 'crimson' : 'inherit' }}>
      {prefix}
      {value > 0 ? '+' : ''}
      {value.toLocaleString()}
    </code>
  );
}

AmountField.defaultProps = {
  ...TextField.defaultProps,
  source: 'amount',
};
export default AmountField;
