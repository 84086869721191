import React from 'react';
import {
  List,
  Edit,
  Create,
  Datagrid,
  TextField,
  EditButton,
  SimpleForm,
  TextInput,
  SelectInput,
  Filter,
  ShowButton,
  DateField,
  Resource,
  ShowGuesser,
  NumberInput,
  DateInput,
  BooleanField,
  BooleanInput,
  Toolbar,
  SaveButton,
  TopToolbar,
  ExportButton,
  FilterButton,
  CreateButton,
} from 'react-admin';

import UserReferenceField from 'shared-fields/UserReferenceField';
import UserReferenceInput from 'shared-fields/UserReferenceInput';
import AmountField from 'shared-fields/AmountField';
import { generateSelectOptions } from 'utils/filters-utils';
import { TransactionState } from 'constants/transaction-state.enum';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import ChannelReferenceField from 'shared-fields/ChannelReferenceField';
import { rolesMatch } from 'utils/user';
import Role from 'constants/role';
import { useHasRole } from 'hooks/useHasRole';
import ChannelReferenceInput from 'shared-fields/ChannelReferenceInput';
import DescriptionField from './DescriptionField';

function WalletTransactionFilter(props) {
  const hasRole = useHasRole();
  return (
    <Filter {...props}>
      <SelectInput source="state" choices={paymentStateChoices} alwaysOn />
      {hasRole(Role.ROLE_ADMIN_USER_VIEW) && <UserReferenceInput alwaysOn />}
      {hasRole(Role.ROLE_ADMIN_CHANNEL_VIEW) && <ChannelReferenceInput alwaysOn />}
    </Filter>
  );
}

export function WalletTransactionListContent() {
  return (
    <Datagrid bulkActionButtons={false}>
      <TextField source="id" />
      <AmountField source="amount" />
      <TextField source="currency" />
      <AmountField source="currencyPriceInTMN" label="Price in TMN" />
      <DescriptionField />
      <UserReferenceField />
      <ChannelReferenceField />
      <BooleanField source="hidden" />
      <DateField source="paidAt" showTime locales="fa-IR" />
      <DateField source="createdAt" showTime locales="fa-IR" />
      <EditButton />
      <ShowButton />
    </Datagrid>
  );
}
export function WalletTransactionList() {
  const hasRole = useHasRole();
  return (
    <List
      sort={{ field: 'id', order: 'DESC' }}
      filters={<WalletTransactionFilter />}
      perPage={25}
      filterDefaultValues={{
        paymentState: TransactionState.SUCCESSFUL,
      }}
      actions={(
        <TopToolbar>
          {hasRole(Role.ROLE_SUPER_ADMIN) && <ExportButton />}
          {hasRole(Role.ROLE_ADMIN_FINANCIAL) && <CreateButton />}
          {/* <FilterButton filters={<WalletTransactionFilter />} /> */}
        </TopToolbar>
      )}
    >
      <WalletTransactionListContent />
    </List>
  );
}

export function WalletTransactionEdit(props) {
  return (
    <Edit {...props}>
      <SimpleForm
        toolbar={(
          <Toolbar>
            <SaveButton />
          </Toolbar>
        )}
      >
        <TextInput source="id" disabled />
        <NumberInput source="amount" disabled />
        <NumberInput source="currencyPriceInTMN" label="Price in TMN" />
        <TextInput source="description" multiline />
        <UserReferenceInput disabled />
        <TextInput source="sheba" />
        <TextInput source="referenceCode" />
        <DateInput source="paidAt" locales="fa-IR" defaultValue={new Date()} />
        <BooleanInput source="hidden" defaultChecked={false} />
      </SimpleForm>
    </Edit>
  );
}

export function WalletTransactionCreate(props) {
  return (
    <Create {...props}>
      <SimpleForm>
        <NumberInput source="amount" defaultValue={0} />
        <TextInput source="description" multiline />
        <UserReferenceInput />
        <SelectInput
          source="currency"
          choices={[
            { id: 'USD', name: 'USD' }, { id: 'TMN', name: 'TMN' }, { id: 'GBP', name: 'GBP' },
          ]}
          defaultValue="GBP"
        />
        <NumberInput source="currencyPriceInTMN" label="Price in TMN" />
        <TextInput source="sheba" />
        <TextInput source="referenceCode" />
        <DateInput source="paidAt" locales="fa-IR" defaultValue={new Date()} />
        <BooleanInput source="hidden" defaultChecked={false} />
      </SimpleForm>
    </Create>
  );
}

const paymentStateChoices = generateSelectOptions(TransactionState);

export const renderTransactionResource = (roles) => (
  <Resource
    options={{
      label: 'Transactions',
    }}
    name="wallet-transaction"
    label="sdf"
    icon={AccountBalanceWalletIcon}
    show={ShowGuesser}
    list={WalletTransactionList}
    edit={rolesMatch({ roles, role: Role.ROLE_ADMIN_WALLET_TRANSACTION_EDIT }) && WalletTransactionEdit}
    create={rolesMatch({ roles, role: Role.ROLE_ADMIN_WALLET_TRANSACTION_CREATE }) && WalletTransactionCreate}
  />
);
