import {
  Box, Typography, TextField, Button,
} from '@mui/material';
import { useRecordContext, useInput } from 'react-admin';
import React from 'react';

export function DescriptionInput(props) {
  const record = useRecordContext() || {};
  const [newText, setNewText] = React.useState('');

  const {
    field,
  } = useInput(props);

  const text = `${newText} - 🗓️ ${new Date().toLocaleDateString('fa-IR')}`;
  const handleAdd = () => {
    const updatedDescription = field.value ? `${field.value}|${text}` : text;
    field.onChange(updatedDescription);
    setNewText('');
  };

  return (
    <Box>
      {field.value && (
        <Box sx={{ direction: 'rtl' }}>
          <ul style={{ listStyle: 'none', padding: 0, margin: 0 }}>
            {field.value.split('|').map((line) => (
              <li key={line}>
                <Typography variant="body2" color="textSecondary">{line}</Typography>
              </li>
            ))}
          </ul>
        </Box>
      )}
      <Box sx={{ mt: 2, display: 'flex', gap: 1 }}>
        <TextField
          multiline
          fullWidth
          value={newText}
          onChange={(e) => setNewText(e.target.value)}
          size="small"
          label="توضیحات ..."
        />
        <Button variant="contained" onClick={handleAdd}>
          ثبت
        </Button>
      </Box>
    </Box>
  );
}

