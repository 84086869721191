import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './style.css';
import { Box, Divider, Typography } from '@mui/material';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { RAW_THEME } from 'theme';
import { getShebaBankName, toPersianDigit } from 'utils/digit';

const theme = createTheme({
  ...RAW_THEME,
  typography: {
    ...RAW_THEME.typography,
    body1: {
      fontWeight: 200,
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 700,
    },
    h3: {
      fontSize: '1.3rem',
      fontWeight: 400,
    },
  },
});

function ContractTether() {
  const {
    state: { record },
  } = useLocation();
  const navigate = useNavigate();
  if (!record.refId) {
    record.refId = prompt('شماره پیگیری قرارداد را وارد نمایید');
  }
  record.name = record.information?.firstName && record.information?.lastName ? `${record.information.firstName} ${record.information.lastName}` : null;
  useEffect(() => {
    const docTitle = document.title;
    function print() {
      document.title = `contact-${record.id}-tether`;
      window.print();
      document.title = docTitle;
      navigate(-1);
    }
    Promise.all([
      ...Array.from(document.images)
        .filter((img) => !img.complete)
        .map(
          (img) => new Promise((resolve) => {
            img.onload = img.onerror = resolve;
          }),
        ),
      document.fonts.ready,
    ]).then(() => {
      console.log('ready to print2');
      setTimeout(() => {
        print();
      }, 1000);
    });
  }, []);

  console.log(record);
  return <Madeha record={record} />;
}

export default ContractTether;

function insert(val, type) {
  if (!val) {
    return (
      <code style={{ backgroundColor: 'crimson', color: '#fff' }}>
        {' '}
        NOT SET
        {' '}
      </code>
    );
  }
  if (type === 'number') {
    return (
      <span style={{ fontWeight: 300 }}>
        {' '}
        {toPersianDigit(val)}
        {' '}
      </span>
    );
  }
  return (
    <span>
      {' '}
      {val}
      {' '}
    </span>
  );
}

function Madeha({ record, channelUrl }) {
  const signSection = (
    <Box sx={{ p: 2 }}>
      <Box sx={{ p: 2 }}>
        <table style={{ borderCollapse: 'collapse' }}>
          <tbody>
            <tr>
              <td
                style={{
                  width: '228pt',
                  paddingRight: '5.5pt',
                  paddingLeft: '5.5pt',
                  verticalAlign: 'top',
                }}
              >
                <Typography>
                  سید مصطفی میرقاسمی
                  <br />
                  نماینده طرف اول
                </Typography>
              </td>
              <td
                style={{
                  width: '228pt',
                  paddingRight: '5.5pt',
                  paddingLeft: '5.5pt',
                  verticalAlign: 'top',
                }}
              >
                <Typography>
                  {insert(record.name)}
                  <br />
                  طرف دوم
                </Typography>
              </td>
            </tr>
          </tbody>
        </table>
      </Box>
    </Box>
  );
  return (
    <ThemeProvider theme={theme}>
      <div className="contract" style={{ textAlign: 'justify' }}>
        <Page footer={signSection}>
          <Divider>به نام خدا</Divider>
          <Box sx={{ textAlign: 'center', mt: 2 }}>
            <img
              src="https://youber.app/wp-content/uploads/2023/09/youber-logo-new.png"
              alt="youber"
              style={{ width: 140 }}
            />
          </Box>
          <Box sx={{ p: 2, pt: 4, pb: 0 }}>
            <Typography fontWeight={800}>
              الحاقیه قرارداد به شماره پیگیری
              {insert(record.refId, 'number')}

            </Typography>
          </Box>

          <Made title="طرفین قرارداد" number={1}>
            <Typography>
              این الحاقیه بین شرکت دانش آوران نور ونداد با شماره ثبت ۷۶۷۵۴ و
              شناسه ملی ۱۴۰۰۹۹۹۹۱۸۲ به نمایندگی آقای سید مصطفی میرقاسمی که از این پس طرف اول نامیده
              می شود و آقای / خانم
              {insert(record.name)}
              به کد ملی
              {insert(record.information.nationalCode, 'number')}
              {/* شماره شناسنامه
        -- */}
              فرزند
              {insert(record.information.fatherName)}

              که از این پس طرف دوم نامیده می شود، منعقد می گردد .
            </Typography>
          </Made>
          <Made number={2} title="تأیید مالکیت کیف پول">
            <Typography>
              طرف دوم به صراحت اعلام می‌دارد که کیف پول شبکه تتر TRC20 با آدرس
              {' '}
              {insert(record.bankAccount.TRC20WalletAddress)}
              . متعلق به وی بوده و از کیف پول‌های متعلق به صرافی‌های ایرانی نمی‌باشد. کاربر مسئولیت کامل در خصوص صحت اطلاعات فوق را بر عهده گرفته و هرگونه عواقب ناشی از اعلام خلاف واقع را می‌پذیرد
            </Typography>
          </Made>

          <Made number={3} title="نحوه پرداخت درآمد">

            <Typography>
              طرف اول متعهد می‌شود درآمد حاصل از کانال یوتیوب طرف دوم را به صورت رمزارز تتر به آدرس کیف پول اعلام شده توسط طرف دوم واریز نماید.
            </Typography>

          </Made>
          <Made number={4} title="مصادره یا مسدودی رمزارزها">
            <Typography>
              طرف دوم آگاه است که در صورت ارسال آدرس کیف پول متعلق به صرافی‌های ایرانی یا هر دلیل دیگری که منجر به مصادره یا مسدود شدن رمزارزهای واریزی توسط شبکه مبادله رمزارز گردد، طرف اول هیچگونه مسئولیتی در قبال این امر نداشته و کاربر حق هیچ‌گونه ادعا یا درخواست خسارتی را از طرف اول نخواهد داشت.
            </Typography>
          </Made>
        </Page>

        <Page footer={signSection}>

          <Made number={5} title="سایر توافقات">
            <Typography>
              سایر مفاد قرارداد اصلی که با این الحاقیه مغایرتی نداشته باشد، کماکان دارای اعتبار خواهد بود.
            </Typography>
          </Made>
          <Typography sx={{ px: 2 }} fontWeight={600}>
            کاربر با امضای این الحاقیه، ضمن تأیید صحت مطالب فوق، به تمامی مفاد آن رضایت داده و متعهد به اجرای کامل آنها می‌گردد.
          </Typography>
        </Page>
      </div>
    </ThemeProvider>
  );
}

function Page({ children, footer }) {
  return (
    <div
      style={{
        pageBreakAfter: 'always',
        pageBreakInside: 'avoid',
        position: 'relative',
        height: '95vh',
        // background: 'rgba(0,0,0,0.1)',
      }}
    >
      {children}
      <div style={{ position: 'absolute', bottom: 0 }}>
        {footer}
      </div>
    </div>
  );
}

function Made({ number, title, children }) {
  return (
    <Box
      sx={{
        py: 3,
        px: 2,
        backgroundColor: '#58585812',
        my: 2,
        borderRadius: 4,
      }}
    >
      <Typography
        variant="h3"
        gutterBottom
        sx={{
          borderImage:
            'linear-gradient(183deg, rgba(253,29,29,0.528470763305322) 26%, rgba(252,176,69,0.6349133403361344) 100%) 1',
          borderWidth: 4,
          borderStyle: 'solid',
          borderBottom: 'none',
          borderLeft: 'none',
          borderTop: 'none',
          px: 2,
        }}
      >
        ماده
        {' '}
        {number?.toLocaleString('fa-IR')}
        {' | '}
        {title}
      </Typography>
      {children}
    </Box>
  );
}
