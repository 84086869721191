import { Box, Tooltip, Typography } from '@mui/material';
import { useRecordContext } from 'react-admin';
import React from 'react';

export function CategoriesField() {
  const record = useRecordContext();
  return (
    <>
      <Typography variant="body2" color="textSecondary">Categories</Typography>
      <ul>
        {record.categories.map((item) => (
          <li key={item}>{item}</li>
        ))}
      </ul>
    </>
  );
}
