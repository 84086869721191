import React from 'react';

import {
  CheckboxGroupInput,
  List,
  Edit,
  Datagrid,
  TextField,
  EditButton,
  TabbedForm,
  FormTab,
  TextInput,
  SelectInput,
  EmailField,
  Filter,
  ShowButton,
  Resource,
  SearchInput,
  Show,
  TabbedShowLayout,
  Tab,
  ReferenceManyField,
  ArrayField,
  ReferenceField,
  SingleFieldList,
  NumberInput,
  TopToolbar,
  FilterButton,
  ExportButton,
  downloadCSV,
  BooleanField,
  BooleanInput,
} from 'react-admin';
import PersonIcon from '@mui/icons-material/Person';
import AmountField from 'shared-fields/AmountField';

import TitleField from 'shared-fields/TitleField';
import Role from 'constants/role';
import { WalletTransactionListContent } from 'views/walletTransaction';
import { ChannelListContent } from 'views/channel';
import { useHasRole } from 'hooks/useHasRole';
import { rolesMatch } from 'utils/user';
import jsonExport from 'jsonexport/dist';
import { getShebaBankName } from 'utils/digit';
import { Divider, Stack } from '@mui/material';
import { ActivityLoggerListContent } from 'views/activity-logger';
import LoginAsButton from './LoginAsButton';
import { ContractStateField } from './ContractStateField';
import BankNameField from './BankNameField';
import DownloadDocButton from './DownloadDocButton';
import DownloadContractButton from './DownloadContractButton';

const exporter = (users) => {
  const usersForExport = users.map((user) => {
    const {
      information: {
        nationalCode, address, postalCode, firstName, lastName,
      } = {}, bankAccount, channels: [{ title: channelTitle, id: channelId, url: channelUrl } = {}] = [{}], ...rest
    } = user;
    console.log(nationalCode, nationalCode?.toString?.()?.padStart(10, '0'));
    return {
      ...rest,
      nationalCode: nationalCode?.toString?.()?.padStart(10, '0'),
      firstName,
      lastName,
      ...bankAccount,
      bankName: getShebaBankName(bankAccount.sheba) || bankAccount.bankName,
      address,
      postalCode,
      channelTitle,
      channelId,
      channelUrl,
    };
  });
  jsonExport(usersForExport, {
    headers: ['id', 'name', 'firstName', 'lastName', 'email', 'gbpCredit', 'sheba', 'cardNumber', 'nationalCode', 'channelId', 'channelTitle', 'channelUrl'], // order fields in the export
  }, (err, csv) => {
    downloadCSV(csv, 'users');
  });
};

function UserFilter(props) {
  return (
    <Filter {...props}>
      <SearchInput source="searchQuery" alwaysOn />
      <SelectInput label="Role" source="role" choices={rolesChoices()} alwaysOn />
      <SelectInput label="Contract" source="contractState" choices={contractStateChoices()} alwaysOn />
      <SelectInput source="preferredPayment" label="Preferred Payment" choices={PreferredPaymentChoices} alwaysOn />
    </Filter>
  );
}

export function UserList(props) {
  const hasRole = useHasRole();
  return (
    <List
      sort={{ field: 'id', order: 'DESC' }}
      filters={<UserFilter />}
      filterDefaultValues={{
      }}
      perPage={25}
      exporter={exporter}
      actions={(
        <TopToolbar>
          {hasRole(Role.ROLE_SUPER_ADMIN) && <ExportButton />}
          {/* <FilterButton filters={<UserFilter />} /> */}
        </TopToolbar>
      )}
      {...props}
    >
      <Datagrid bulkActionButtons={false}>
        <TextField source="id" />
        <TextField source="email" />
        <TextField source="phoneNumber" />
        <TextField source="information.firstName" label="FirstName" />
        <TextField source="information.lastName" label="LastName" />
        <AmountField source="gbpCredit" />
        <TextField source="preferredPayment" label="Preferred Payment" />
        <ContractStateField />
        {hasRole(Role.ROLE_ADMIN_CHANNEL_VIEW) && (
          <ArrayField source="channels">
            <SingleFieldList linkType={false}>
              <ReferenceField label="Channel" reference="channel" source="id" />
            </SingleFieldList>
          </ArrayField>
        )}
        {hasRole(Role.ROLE_SUPER_ADMIN) && <LoginAsButton />}
        {hasRole(Role.ROLE_ADMIN_USER_EDIT) && <EditButton />}
        <ShowButton />
      </Datagrid>
    </List>
  );
}

export function UserEdit(props) {
  const hasRole = useHasRole();
  return (
    <Edit mutationMode="optimistic" title={<TitleField />} {...props}>
      <TabbedForm>
        <FormTab label="General">
          <TextInput source="id" disabled />
          {/* <TextInput source="name" /> */}
          <TextInput source="information.firstName" label="First Name" />
          <TextInput source="information.lastName" label="Last Name" />
          <TextInput source="email" />
          <NumberInput source="usdCredit" label="USD Credit" disabled />
          <NumberInput source="gbpCredit" label="GBP Credit" disabled />
          <SelectInput source="contractState" choices={contractStateChoices()} />
          {
            hasRole(Role.ROLE_SUPER_ADMIN) && (
              <CheckboxGroupInput source="roles" choices={rolesChoices()} />
            )
          }
        </FormTab>

        {
          hasRole(Role.ROLE_ADMIN_WALLET_TRANSACTION_VIEW) && (
            <FormTab label="Transactions">
              <TransactionTabContent />
            </FormTab>
          )
        }
        {
          hasRole(Role.ROLE_ADMIN_CHANNEL_VIEW) && (
            <FormTab label="Channels">
              <ChannelTabContent />
            </FormTab>
          )
        }

        <FormTab label="information">
          <TextInput source="phoneNumber" />
          <TextInput source="information.firstNameEnglish" label="First Name" />
          <TextInput source="information.lastNameEnglish" label="Last Name" />
          <TextInput source="information.nationalCode" label="National Code" />
          <TextInput source="information.address" label="Address" multiline />
          <TextInput source="information.postalCode" label="Postal Code" />
          <TextInput source="information.fatherName" label="Father's name" />
          <NumberInput source="information.birthYear" label="Birth Year" />
        </FormTab>
        <FormTab label="Financial">
          <SelectInput source="preferredPayment" label="Preferred Payment" choices={PreferredPaymentChoices} />
          <TextInput source="bankAccount.sheba" label="Sheba" />
          <TextInput source="bankAccount.cardNumber" label="Card Number" />
          <BankNameField />
          <Divider sx={{ mb: 5 }} />
          <TextInput
            source="bankAccount.TRC20WalletAddress"
            label="TRC20 Wallet"
            sx={{ width: 350 }}
          />
        </FormTab>
      </TabbedForm>

    </Edit>
  );
}

function TransactionTabContent(props) {
  return (
    <ReferenceManyField
      {...props}
      perPage={100}
      reference="wallet-transaction"
      target="userId"
      addLabel={false}
    >
      <WalletTransactionListContent />
    </ReferenceManyField>
  );
}
function ChannelTabContent(props) {
  return (
    <ReferenceManyField
      {...props}
      perPage={100}
      reference="channel"
      target="userId"
      addLabel={false}
    >
      <ChannelListContent />
    </ReferenceManyField>
  );
}
function ActivityTabContent(props) {
  return (
    <ReferenceManyField
      {...props}
      perPage={100}
      reference="activity-logger"
      target="recordId"
      addLabel={false}
      filter={{ recordModel: 'User' }}
    >
      <ActivityLoggerListContent />
    </ReferenceManyField>
  );
}
export function UserShow(props) {
  const hasRole = useHasRole();
  return (
    <Show {...props}>
      <TabbedShowLayout>
        <Tab label="general">
          <TextField source="information.firstName" />
          <TextField source="information.lastName" />
          <TextField source="name" label="name(deprecated)" />
          <EmailField source="email" />
          <TextField source="contractState" />
          <AmountField source="gbpCredit" />
          <Divider>Docs</Divider>
          <Stack direction="row" useFlexGap flexWrap="wrap" gap={2}>
            <DownloadDocButton title="National Card Front" type="nationalCardImage" />
            <DownloadDocButton title="National Card Back" type="nationalCardBackImage" />
            <DownloadContractButton />
          </Stack>

        </Tab>
        {
          hasRole(Role.ROLE_ADMIN_WALLET_TRANSACTION_VIEW) && (
            <Tab label="Transactions">
              <TransactionTabContent />
            </Tab>
          )
        }
        {
          hasRole(Role.ROLE_ADMIN_CHANNEL_VIEW) && (
            <Tab label="Channels">
              <ChannelTabContent />
            </Tab>
          )
        }
        <Tab label="Information">
          <TextField source="phoneNumber" label="Phone Number" />
          <TextField source="information.nationalCode" label="National Code" />
          <TextField source="information.address" label="Address" />
          <TextField source="information.postalCode" label="Postal Code" />
          <TextField source="information.fatherName" label="Father Name" />
          <TextField source="information.birthYear" label="Birth Year" disabled />
        </Tab>
        <Tab label="Financial">
          <TextField source="bankAccount.sheba" label="Sheba" />
          <TextField source="bankAccount.cardNumber" label="Card Number" />
          <BankNameField />
          <TextField source="preferredPayment" label="Preferred Payment" />
          <TextField
            source="bankAccount.TRC20WalletAddress"
            label="TRC20 Wallet"
            sx={{ width: 350 }}
          />
        </Tab>
        {
          hasRole(Role.ROLE_SUPER_ADMIN) && (
            <Tab label="history">
              <ActivityTabContent />
            </Tab>
          )
        }
      </TabbedShowLayout>
    </Show>
  );
}

function rolesChoices() {
  return Object.entries(Role).map((role) => ({
    id: role[0],
    name: role[1]
      .replace('ROLE_', '')
      .replace('ADMIN_', '')
      .toLocaleLowerCase(),

  }));
}
function contractStateChoices() {
  return ['NONE', 'SIGNED_BY_US', 'REGISTERED', 'COMPLETED'].map((s) => ({ id: s, name: s }));
}
const PreferredPaymentChoices = [
  'IRT_PAYMENT', 'USDT_PAYMENT', 'GBP_DEPOSIT',
].map((p) => ({ id: p, name: p }));

export function renderUserResource(roles) {
  return (
    <Resource
      name="user"
      icon={PersonIcon}
      list={UserList}
      edit={rolesMatch({ roles, role: Role.ROLE_ADMIN_USER_EDIT }) && UserEdit}
      show={UserShow}
    />
  );
}
