import React from 'react';
import {
  List,
  Edit,
  Create,
  Datagrid,
  TextField,
  EditButton,
  SimpleForm,
  TextInput,
  SelectInput,
  Filter,
  ShowButton,
  DateField,
  Resource,
  ShowGuesser,
  NumberInput,
  Toolbar,
  SaveButton,
  TopToolbar,
  ExportButton,
  CreateButton,
} from 'react-admin';

import AmountField from 'shared-fields/AmountField';
import { generateSelectOptions } from 'utils/filters-utils';
import { TransactionState } from 'constants/transaction-state.enum';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import ChannelReferenceInput from 'shared-fields/ChannelReferenceInput';
import ChannelReferenceField from 'shared-fields/ChannelReferenceField';
import { rolesMatch } from 'utils/user';
import Role from 'constants/role';
import { useHasRole } from 'hooks/useHasRole';
import PaidIcon from '@mui/icons-material/Paid';

function IncomeFilter(props) {
  return (
    <Filter {...props}>
      <SelectInput source="description" choices={paymentStateChoices} alwaysOn />
      <ChannelReferenceInput alwaysOn />
    </Filter>
  );
}

export function IncomeListContent() {
  const hasRole = useHasRole();
  return (
    <Datagrid bulkActionButtons={false}>
      <TextField source="id" />
      <AmountField source="amount" />
      <TextField source="currency" />
      <TextField source="description" />
      <ChannelReferenceField />
      <DateField source="createdAt" showTime locales="fa-IR" />
      {
        hasRole(Role.ROLE_ADMIN_INCOME_EDIT) && (
          <EditButton />
        )
      }
      <ShowButton />
    </Datagrid>
  );
}
export function IncomeList() {
  const hasRole = useHasRole();
  return (
    <List
      sort={{ field: 'id', order: 'DESC' }}
      filters={<IncomeFilter />}
      perPage={25}
      actions={(
        <TopToolbar>
          {hasRole(Role.ROLE_SUPER_ADMIN) && <ExportButton />}
          {hasRole(Role.ROLE_ADMIN_FINANCIAL) && <CreateButton />}
          {/* <FilterButton filters={<IncomeFilter />} /> */}
        </TopToolbar>
      )}
    >
      <IncomeListContent />
    </List>
  );
}

export function IncomeEdit(props) {
  return (
    <Edit {...props}>
      <SimpleForm toolbar={(
        <Toolbar>
          <SaveButton />
        </Toolbar>
      )}
      >
        <TextInput source="id" disabled />
        <NumberInput source="amount" disabled />
        <TextInput source="currency" disabled />
        <TextInput source="description" multiline />
        <ChannelReferenceInput disabled />
      </SimpleForm>
    </Edit>
  );
}

export function IncomeCreate(props) {
  return (
    <Create {...props}>
      <SimpleForm>
        <NumberInput source="amount" />
        <TextInput source="currency" />
        <TextInput source="description" />
        <ChannelReferenceInput />
      </SimpleForm>
    </Create>
  );
}

const paymentStateChoices = generateSelectOptions(TransactionState);

export const renderIncomeResource = (roles) => (
  <Resource
    name="Income"
    icon={PaidIcon}
    show={ShowGuesser}
    list={IncomeList}
    edit={rolesMatch({ roles, role: Role.ROLE_ADMIN_INCOME_EDIT }) && IncomeEdit}
    create={rolesMatch({ roles, role: Role.ROLE_ADMIN_INCOME_CREATE }) && IncomeCreate}
  />
);
